export enum TealiumEventTypes {
  EVENT = 'event',
  VIEW = 'view',
}

export enum TealiumKeys {
  VIEW_SEARCH_PAGE = 'vw_search_page',
  HERO_VIDEO_CTA_PLAY = 'hero-video-cta-play',
  DOWNLOAD_KEY_SEARCH = 'download-pdf-asset-search',
  PLAY_BUTTON_SEARCH = 'play-video-button-search',
}

export enum TopNavComponents {
  NAVBAR = 'navbar',
  NAVBAR_POPUP = 'navbar-popup',
  SUBNAV = 'sub-navbar',
}
