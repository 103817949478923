import { MultipleQueriesQuery } from '@algolia/client-search';
import { OptionTypeBase } from 'react-select';
import { SupportedLanguages } from '../enums/locale';
import sortBy from 'lodash/sortBy';
import {
  ALGOLIA_SEARCH_INDICES,
  LOCALE_INDICES_SUFFIXES,
  PRODUCT_FIELDS,
  PROGRAMS_FACETS,
  PROGRAM_FIELDS,
  SHOP_SUPPORTED_LANGUAGES,
  SORT_INDICES,
  SUPPORTED_LANGUAGES,
} from '../enums/algolia';
import { getNameFromLabel } from '../widgets/CurrentRefinements/utils';
import { IFacet, ISort } from '../interfaces/ISearch';

const { PRODUCT, PROGRAMS } = ALGOLIA_SEARCH_INDICES;
const { DESCRIPTION, IMAGES, SLUG, TITLE } = PROGRAM_FIELDS;
const { SORT_BY_DATE, SORT_BY_TITLE } = SORT_INDICES;

export const getIndexLocaleSuffix = (locale: SupportedLanguages): string => {
  const supportedLanguage =
    Object.values(SUPPORTED_LANGUAGES).find((language) => language === locale.toString()) || '';

  return LOCALE_INDICES_SUFFIXES[supportedLanguage] || '';
};

export const getShopIndexLocaleSuffix = (locale: SupportedLanguages): string => {
  const supportedLanguage =
    Object.values(SHOP_SUPPORTED_LANGUAGES).find((language) => language === locale.toString()) ||
    '';

  return LOCALE_INDICES_SUFFIXES[supportedLanguage] || '';
};

export const getTopResultQueries = (
  locale: SupportedLanguages,
  query: string,
): MultipleQueriesQuery[] => {
  const productLocaleSuffix = getShopIndexLocaleSuffix(locale);
  const localeSuffix = getIndexLocaleSuffix(locale);
  const filters = `title:"${query}"`;

  return [
    {
      indexName: `${PRODUCT}${productLocaleSuffix}`,
      query,
      params: {
        hitsPerPage: 1,
        filters,
        attributesToRetrieve: [PRODUCT_FIELDS.TITLE, PRODUCT_FIELDS.IMAGES, PRODUCT_FIELDS.URL],
      },
    },
    {
      indexName: `${PROGRAMS}${localeSuffix}`,
      query,
      params: {
        hitsPerPage: 1,
        filters,
        attributesToRetrieve: [PROGRAMS_FACETS.TYPE, DESCRIPTION, IMAGES, SLUG, TITLE],
      },
    },
  ];
};

const iteratees = (value: string) => getNameFromLabel(value);

export const parseFacets = (
  facets: Record<string, Record<string, number>> | undefined,
  facetsList: Array<{ id: string; hasEmbeddedAttributes: boolean }>,
): {
  [facet: string]: Array<IFacet>;
} =>
  facetsList.reduce((acc, { id, hasEmbeddedAttributes }) => {
    const values = facets ? Object.keys(facets[id] || {}) : [];
    const sortedFacets = hasEmbeddedAttributes ? sortBy(values, [iteratees]) : values;

    return {
      ...acc,
      [id]: sortedFacets.map((facet) => ({
        label: facet,
        value: [facet],
        isRefined: false,
        count: 0,
      })),
    };
  }, {});

export const findInOptions = (
  options: Array<OptionTypeBase>,
  value: string,
): OptionTypeBase | undefined => options.find(({ value: optionValue }) => optionValue === value);

export const getDecodedSortValues = (indexName: string): ISort => ({
  date: `${indexName}_${SORT_BY_DATE}`,
  title: `${indexName}_${SORT_BY_TITLE}`,
  popularity: indexName,
});

export const getEncodedSortValues = (indexName: string): ISort => ({
  [`${indexName}_${SORT_BY_DATE}`]: 'date',
  [`${indexName}_${SORT_BY_TITLE}`]: 'title',
  [indexName]: 'popularity',
});

export const buildAlgoliaFilter = (...args: string[]): string =>
  args
    .filter((arg) => arg !== '')
    .map((arg) => `(${arg})`)
    .join(' AND ');
