import { h, ComponentChild } from 'preact';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { store } from '../../configureStore';
import { TARGET } from '../../enums/common';
import { IItemModel, LinkLocations } from '../../models/Item';
import * as tealiumActions from '../../actions/tealiumActions';
import { ITealiumThunkActions } from '../../actions/interfaces/ITealiumActions';
import { navigationRedirectActionCreator } from '../../actions/navigationActions';
import { INavigationActionCreators } from '../../actions/interfaces/INavigationAction';
import { TopNavComponents } from '../../enums/tealium';
import { useEventHandler } from '../../providers/eventHandlerProvider';
import { SupportedAmplitudeEvents, SupportedEvents } from '../../enums/event';
import { forwardRef } from 'preact/compat';

const { dispatch } = store;
const { sendLinkTrackingEvent, redirect } = bindActionCreators<
  INavigationActionCreators,
  ITealiumThunkActions
>(
  {
    sendLinkTrackingEvent: tealiumActions.sendLinkTrackingEvent,
    redirect: navigationRedirectActionCreator,
  },
  dispatch,
);

export interface IAppLinkProps {
  id?: string;
  role?: string;
  item: IItemModel;
  tealiumComponent?: TopNavComponents;
  onClick?(event: Event): void;
  onClose?(): void;
  onFocus?(): void;
  onBlur?(event: FocusEvent): void;
  children: ComponentChild;
}

interface IAppLinkContainerProps extends HTMLAnchorElement {
  isClickable: boolean;
}

export const Container = styled.a<IAppLinkContainerProps>`
  pointer-events: ${({ isClickable }) => (isClickable ? 'all' : 'none')};
  color: ${({ theme }) => theme.darkGray};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.oneAppBlue};
  }
  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
  }
`;

const AppLink = forwardRef(
  ({ children, item, onClose, onClick, tealiumComponent, ...props }: IAppLinkProps, ref) => {
    const { dispatchEvent } = useEventHandler();

    const handleOnClick = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();

      sendLinkTrackingEvent(item, tealiumComponent || TopNavComponents.NAVBAR_POPUP);

      dispatchEvent(SupportedEvents.onAnalyticsEvent, {
        eventType: SupportedAmplitudeEvents.selectNavigationItem,
        data: { itemTitle: item?.itemName },
      });

      if (!!onClick) {
        onClick(event);
      } else {
        redirect(item.parsedLink, item.linkLocation === LinkLocations.sameWindow);
      }

      if (!!onClose) {
        onClose();
      }
    };

    return (
      <Container
        {...props}
        ref={ref}
        href={item.parsedLink}
        isClickable={item.parsedLink || !!onClick}
        target={item.linkLocation === LinkLocations.sameWindow ? TARGET.SELF : TARGET.BLANK}
        onClick={handleOnClick}
      >
        {children}
      </Container>
    );
  },
);

export default AppLink;
