{
  "ACCESS_POLICIES": {
    "APES_API": "https://api.prod.cd.beachbodyondemand.com/user-apes/apes",
    "APES_API_KEY": "ny3fXZ8nPVa39ar5wg0ht6G2xrqvqCIK3MtpoLwJ"
  },
  "ALGOLIA": {
    "APP_ID": "JW2BCPBIT3",
    "API_KEY": "2501f76b2121232f72a3ecda634efe39",
    "INDEXES": {
      "LESSONS": "LESSONS_APPSYNC",
      "PRODUCTS": "unification_product_catalog",
      "PROGRAMS": "PROGRAMS_APPSYNC",
      "PROGRAM_MATERIALS": "PROGRAM_MATERIALS_APPSYNC",
      "RECIPES": "RECIPES_APPSYNC",
      "WORKOUTS": "WORKOUTS_APPSYNC",
      "OTHER": "OTHER_VIDEOS_APPSYNC",
      "SUGGESTIONS": "AUTO_SUGGESTIONS"
    },
    "FACETS": {
      "RECIPES": {
        "CONTAINER": "videoDetailsModalRecipeSection.containerRowOne.facet",
        "DISH": "Dish.title",
        "MAIN_INGREDIENT": "MainIngredient.title",
        "MEAL": "Meal.facet",
        "PROGRAM": "program.facet",
        "WORKS_WELL_FOR": "videoDetailsModalContentGroupOne.items.facet"
      },
      "WORKOUTS": {
        "BODY_PART": "bodyParts",
        "DURATION": "videoDurationMinutes",
        "EQUIPMENT": "requiredEquips",
        "LEVEL": "workoutLevels",
        "PROGRAM": "program.title",
        "TIER": "tier",
        "TRAINER": "trainer.name",
        "TYPE": "workoutTypes.items"
      },
      "LESSONS": {
        "PROGRAM": "program.title"
      },
      "PROGRAMS": {
        "DURATION": "programDuration.range",
        "TRAINER": "trainer.name",
        "TYPE": "workoutType.title"
      },
      "PROGRAMS_MATERIALS": {
        "PROGRAM": "program.title"
      },
      "PRODUCT": {
        "CATEGORY": "filters.ByParentCategory",
        "CUSTOMER_TYPE": "customerTypesForView",
        "CUSTOMER_ROLES": "customerRoles",
        "GEAR": "filters.ByTypeofGear",
        "GENDER": "filters.ByGender",
        "FLAVOR": "filters.ByFlavor",
        "LEVEL": "filters.ByProgramLevel",
        "PROGRAM": "filters.ByProgram",
        "PROGRAM_TYPE": "filters.ByProgramTypes",
        "SIZE": "filters.BySize",
        "STYLE": "filters.ByStyle",
        "SUBCATEGORY_SHAKEOLOGY": "subParentCategory.Shakeology",
        "TITLE": "title",
        "TRAINER": "filters.ByTrainer",
        "TYPE": "filters.ByOfType"
      }
    }
  },
  "ALL_ACCESS": "https://fs6.formsite.com/beachbody/form161/index.html",
  "APPSYNC": {
    "API_URL": "https://kgeaco34efax5g6zyofnvgtife.appsync-api.us-west-2.amazonaws.com/graphql",
    "API_KEY": "da2-4g2il7newvhcndanv4wic4a73e",
    "CACHE_EXPIRE_MINUTES": 15,
    "IS_PREVIEW": false
  },
  "APP_DOMAIN_LIST": {
    "TBB": "https://www.teambeachbody.com",
    "BB": "https://bodi.com/bodcheckout?referralProgramId=BODi",
    "BLOG": "https://www.beachbodyondemand.com/blog",
    "COO": "https://coach.teambeachbody.com",
    "SHAC": "https://shareacart.teambeachbody.com",
    "CO": "https://coach.teambeachbody.com",
    "BOD": "https://www.beachbodyondemand.com",
    "CMS": "bod-cms-assets"
  },
  "BOD_CHECKOUT_URL": "/shop/{{countryCode}}/d/bodcheckout",
  "BOD_GROUP_URL": "/groups/{id}/portal",
  "BODI": {
    "NUTRITION_PLUS_URL": "/d/bodcheckout",
    "ENTITLEMENT": "BODINT"
  },
  "BODI_VIDEOS_DEEP_LINKING_ENABLED_FLAG": false,
  "CART_API": {
    "URL": "https://www.teambeachbody.com/rest/model/atg/commerce/ShoppingCartActor/bodsummary"
  },
  "COCOA_API": {
    "URL": "https://cocoa-prod.prod.cd.beachbodyondemand.com/v1.0",
    "KEY": "Y7Xa9iTYjY4hRlOp10QFG53A4TH16BG885GpbZ3i"
  },
  "CONTENFUL_DOMAINS": {
    "BeachbodyOnDemand.com": "BOD",
    "TeamBeachbody.com": "TBB",
    "BODBlog": "BLOG",
    "CoachOffice": "COO",
    "ShareACart": "SHAC"
  },
  "DOMAIN_WHITELIST": [
    ".beachbody.com",
    ".beachbodyondemand.com",
    ".coo.tbbtest.com",
    ".teambeachbody.com",
    "preview.teambeachbody.local"
  ],
  "ENTITLEMENTS_API": {
    "API_KEY": "rKmdhM06td4O7tDvEplY7NVjmYiFqVBa9atawhui",
    "URL": "https://ebs.prod.gateway.beachbody.com/userEntitlements"
  },
  "GROUPS_API": {
    "URL": "https://api.prod.cd.beachbodyondemand.com/groups-api/v2/profile/groups/",
    "API_KEY": "Y7Xa9iTYjY4hRlOp10QFG53A4TH16BG885GpbZ3i"
  },
  "IFRAME_URL": "https://unification.prod.cd.beachbodyondemand.com/latest/iframe/index.html",
  "PROFILE": {
    "URL": "https://api.prod.cd.beachbodyondemand.com",
    "API_KEY": "ny3fXZ8nPVa39ar5wg0ht6G2xrqvqCIK3MtpoLwJ"
  },
  "GEOLOCATION": {
    "URL": "https://www.beachbodyondemand.com/api/geo"
  },
  "VIDEO_PLAYER": {
    "VHS_API": "https://api.prod.cd.beachbodyondemand.com/vhs/v2",
    "API": "https://api.prod.cd.beachbodyondemand.com/video-api/v2",
    "VHS_API_KEY": "Y7Xa9iTYjY4hRlOp10QFG53A4TH16BG885GpbZ3i",
    "LEGAL_API": "https://devices-static.beachbodyondemand.com/v2/legal",
    "CHROMECAST_APP_ID": "D998E862",
    "API_KEY": "Y7Xa9iTYjY4hRlOp10QFG53A4TH16BG885GpbZ3i",
    "CONVIVA_CUSTOMER_KEY": "3e6281cdf7991c82a612a2d3891ca86ada88e22c",
    "USE_COCOA_API": true,
    "RETRY_ATTEMPTS": 2,
    "RATINGS_API_KEY": "CCOGMiX1XH2iaERs5LavL6UMo47OaYUu3f8n4o9P",
    "RATINGS_API": "https://api.prod.cd.beachbodyondemand.com/ratings-api/ratings"
  },
  "VIDEO_PLAYER_WAIVER": {
    "API_KEY": "Y7Xa9iTYjY4hRlOp10QFG53A4TH16BG885GpbZ3i",
    "URL": "https://api.prod.cd.beachbodyondemand.com/waivers-api/waivers"
  },
  "TBB_MY_ACCOUNT_PATH": "/shop/{countryCode}/account/settings",
  "TBB_SHOP_LIST": {
    "en_ca": "7000002",
    "fr_ca": "7000002",
    "fr_fr": "TBB-FR",
    "en_gb": "TBB-GB",
    "en_us": "1400002",
    "es_us": "1400002"
  },
  "ELEMENT_ID_MAP": {
    "CO": {
      "footer": "global-footer",
      "topNav": "global-topNav"
    },
    "BOD": {
      "footer": "unification-footer",
      "topNav": "unification-topNav"
    },
    "TBB": {
      "footer": "globalFooter",
      "topNav": "globalNav"
    },
    "COO": {
      "topNav": "global-topNav",
      "footer": "global-footer"
    },
    "SHAC": {
      "topNav": "globalNav",
      "footer": "globalFooter"
    }
  },
  "BODI_PROMO_MODAL_ENABLED_FLAG": true,
  "IS_TEALIUM_ENABLED_FLAG": true,
  "IS_TEST_IDS_ENABLED_FLAG": false,
  "IS_ALGOLIA_BACKEND_SEARCH_ENABLED_FLAG": false,
  "PERSISTENT_URL_PARAMS": [
    "tracking",
    "source",
    "icid",
    "trainername",
    "programid",
    "programname",
    "referralprogramid",
    "referringrepid",
    "locale"
  ],
  "HIDDEN_SHOPPING_CATEGORIES": [],
  "BODI_MULTIPLE_TRAINER_TBB_PARAMS": {
    "Idalis & Megan": "IdalisVelasquez-MeganDavies",
    "Amoila & Lacee": "AmoilaCesar-LaceeGreen",
    "Amoila & Joel": "AmoilaCesar-JoelFreeman",
    "Jericho & Elise": "JerichoMcMatthews-EliseJoan",
    "Elise & Ilana": "EliseJoan-IlanaMuhlstein",
    "Autumn & Shaun T": "AutumnCalabrese-ShaunT"
  },
  "DIRECT_RESPONSE_MARKETING_REGIONS": ["XX"],
  "SEARCH_BAR_DEBOUNCE_INTERVAL_MS": 400,
  "VERSION_NAME_URL": "https://unification.prod.cd.beachbodyondemand.com/latest/version.txt",
  "PDF_RECIPE_TEMPLATE_ENABLED_FLAG": false,
  "RELATIVE_PATH": "https://unification.prod.cd.beachbodyondemand.com/latest/script"
}
