import get from 'lodash/get';
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import type { VideoPlayerProps } from 'web-video-player/dist/typings';
import { AppState } from '../../interfaces/appState';
import { useEventHandler } from '../../providers/eventHandlerProvider';
import {
  isAccessTokenLoadingSelector,
  modulesByKeySelector,
} from '../../selectors/modulesSelectors';
import { loadFromConfig } from '../../utils/configLoader';
import { ILocaleModel } from '../../models/Locale';
import { ISsoModel } from '../../interfaces/ISso';
import { SupportedEvents } from '../../enums/event';
import { ISearchModel } from '../../models/Search';
import { CloseButton, VideoPortalContainer } from './videoPlayerStyled';
import CloseIcon from '../../assets/whiteClose.svg';
import { VideoPlayerLocale } from './locale';
import { getFeatureFlagValue } from '../../utils/featureFlags';
import { FEATURE_FLAGS } from '../../enums/featureFlags';
import { getWaiverStatus } from '../../services/waiverService';
import VideoWaiver from '../../components/videoWaiver';
import Promo from '../promo';
import { setShouldLoadVideoHistory } from '../../actions/vhsActions';
import { sendVideoPlayerEvent } from '../../actions/tealiumActions';
import { updateAccessToken } from '../../actions/ssoActions';
import { IMainModel } from '../../models/Main';
import {
  getUserWaiverContentActionCreator,
  setNoWaiverRequiredAction,
  setIsWaiverAnonymousUsersRequiredAction,
  setUserWaiverAcceptedActionCreator,
} from '../../actions/waiverActions';
import {
  setIsPromoScreenOpen,
  setIsWaiverChecked,
  setIsWaiverRequired,
  setIsWaiverLoading,
  setVideoPlayerActionsMap,
  setVideoPlayerOpen,
  setVideoPlayerProps,
} from '../../actions/videoPlayerActions';
import {
  hasLoadedContentSelector,
  isWaiverAnonymousUsersRequiredSelector,
  noWaiverRequiredListSelector,
  userWaiverContentSelector,
} from '../../selectors/waiverSelector';
import { isUserAuthenticatedSelector } from '../../selectors/lockContentSelectors';
import { setVideoData } from '../../actions/searchActions';
import { IVideoPlayerPropsEvent } from '../../actions/interfaces/ITealiumActions';
import { LoggedStatus } from '../../enums/sso';
import { IVideoPlayerState } from '../../reducers/videoPlayerReducer';
import { VideoPlayerSpinner } from './playerSpinner';
import { useWebVideoPlayerLazy } from '../../hooks/useWebVideoPlayerLazy';
import { useNavigation } from '../../hooks/useNavigation';
import { BODRoutes } from '../../enums/routes';
import { TEN_MINUTE_TRAINER } from '../../enums/algolia';
import { getCurrentDevice } from '../../utils/common';
import { DeviceTypes } from '../../utils/device';
import { DEVICE_TYPES } from '../../enums/common';
import { resolveRelativeUrl } from '../../utils/device';

const { DOWNLOAD_APP } = BODRoutes;
const { BOD } = loadFromConfig('APP_DOMAIN_LIST');

const VIDEO_PLAYER = loadFromConfig('VIDEO_PLAYER');
const COCOA_API = loadFromConfig('COCOA_API');

export const DEFAULT_BODI_PLAYBACK_TYPE = 'standard' as const;
export const FREE_WORKOUT_TYPE = 'free-workout';
export const DEFAULT_ADBLOCK_ID = 'AD_BLOCKER_ACTIVE';
const tealiumVisitorId = get(window, 'utag.data.tealium_visitor_id', DEFAULT_ADBLOCK_ID);
const tealiumSessionId = get(window, 'utag.data.tealium_session_id', DEFAULT_ADBLOCK_ID);

const isBODiPromoModalEnabled = () =>
  getFeatureFlagValue(FEATURE_FLAGS.BODI_PROMO_MODAL_ENABLED_FLAG);

export interface IPlayerProps {
  isAccessTokenLoading: boolean;
  isOpen: IVideoPlayerState['isOpen'];
  isWaiverChecked: IVideoPlayerState['isWaiverChecked'];
  isWaiverRequired: IVideoPlayerState['isWaiverRequired'];
  isWaiverLoading: IVideoPlayerState['isWaiverLoading'];
  isPromoScreenOpen: IVideoPlayerState['isPromoScreenOpen'];
  playerProps: IVideoPlayerState['playerProps'];
  searchVideoData: ISearchModel['videoData'];
  main: IMainModel;
  locale: ILocaleModel;
  sso: ISsoModel;
  isUserAuthenticated: boolean;
  userWaiverContent: string;
  userWaiverHasLoaded: boolean;
  noWaiverRequiredList: Array<string>;
  isWaiverAnonymousUsersRequired: boolean;
  setVideoPlayerOpen: typeof setVideoPlayerOpen;
  setIsWaiverChecked: typeof setIsWaiverChecked;
  setIsWaiverRequired: typeof setIsWaiverRequired;
  setIsWaiverLoading: typeof setIsWaiverLoading;
  setIsPromoScreenOpen: typeof setIsPromoScreenOpen;
  setVideoPlayerProps: typeof setVideoPlayerProps;
  setVideoPlayerActionsMap: typeof setVideoPlayerActionsMap;
  setSearchVideoData: typeof setVideoData;
  getUserWaiverContent: typeof getUserWaiverContentActionCreator;
  setNoWaiverRequired: typeof setNoWaiverRequiredAction;
  setIsWaiverAnonymousUsersRequired: typeof setIsWaiverAnonymousUsersRequiredAction;
  setUserWaiverAccepted: typeof setUserWaiverAcceptedActionCreator;
  setShouldLoadVideoHistory: typeof setShouldLoadVideoHistory;
  sendVideoPlayerEvent: typeof sendVideoPlayerEvent;
  updateAccessToken: typeof updateAccessToken;
}

export const Player: FunctionalComponent<IPlayerProps> = ({
  isAccessTokenLoading,
  isOpen,
  isWaiverChecked,
  isWaiverRequired,
  isWaiverLoading,
  isPromoScreenOpen,
  main,
  playerProps,
  searchVideoData,
  locale,
  sso,
  userWaiverContent,
  userWaiverHasLoaded,
  noWaiverRequiredList,
  isWaiverAnonymousUsersRequired,
  isUserAuthenticated,
  setVideoPlayerOpen,
  setIsWaiverChecked,
  setIsWaiverRequired,
  setIsWaiverLoading,
  setIsPromoScreenOpen,
  setVideoPlayerProps,
  setVideoPlayerActionsMap,
  setSearchVideoData,
  getUserWaiverContent,
  setUserWaiverAccepted,
  setNoWaiverRequired,
  setIsWaiverAnonymousUsersRequired,
  setShouldLoadVideoHistory,
  sendVideoPlayerEvent,
  updateAccessToken,
}: IPlayerProps) => {
  const { t } = useTranslation();
  const { dispatchEvent, dispatchEventWithResponse } = useEventHandler();
  const { redirect } = useNavigation();
  const deviceType = getCurrentDevice() as DEVICE_TYPES;
  const isMobile = [DEVICE_TYPES.ANDROID, DEVICE_TYPES.IOS].includes(deviceType);
  const isMobileDevice = isMobile || main.device === DeviceTypes.mobile;
  const [playerContainerElement, setPlayerContainerElement] = useState<HTMLElement | null>(null);

  const userGUID = playerProps?.userGUID || sso?.userInfo?.guid || '';
  const videoGUID = playerProps?.videoGUID || searchVideoData?.videoGuid || '';

  const bodiData = useMemo(() => {
    if (playerProps?.bodiData) return playerProps?.bodiData;
    else if (searchVideoData?.isBODi) return { playbackType: DEFAULT_BODI_PLAYBACK_TYPE };
    else return undefined;
  }, [playerProps?.bodiData, searchVideoData?.isBODi]);

  const isBODi = !!bodiData?.playbackType;

  const brandCode =
    playerProps?.videoBrandCode ||
    searchVideoData?.brandCode ||
    (isBODi ? TEN_MINUTE_TRAINER : undefined);

  const getAccessToken = useMemo(() => {
    if (sso.userLoginStatus !== LoggedStatus.loggedIn) return undefined;

    let accessToken = sso.accessToken || '';

    return async function getAccessToken(forceRefresh?: boolean) {
      if (forceRefresh) {
        accessToken = await dispatchEventWithResponse(SupportedEvents.onRefreshToken);
        updateAccessToken(accessToken);
      }

      return {
        tokenType: 'Bearer',
        accessToken,
      };
    };
  }, [sso.userLoginStatus, sso.accessToken, dispatchEventWithResponse, updateAccessToken]);

  const refreshAccessToken = useMemo(() => {
    return async function refreshAccessToken() {
      let accessToken = '';

      if (playerProps?.getAccessToken) {
        const data = await playerProps.getAccessToken(true);
        accessToken = data?.accessToken;
      } else {
        accessToken = getAccessToken ? (await getAccessToken(true))?.accessToken : '';
      }

      updateAccessToken(accessToken);
      return accessToken;
    };
  }, [getAccessToken, playerProps, updateAccessToken]);

  // lazy load web-video-player module on demand
  const WebVideoPlayer = useWebVideoPlayerLazy({
    load: isOpen,
  });

  const getShouldShowPromoScreen = useCallback(() => {
    return (
      !isMobileDevice &&
      !playerProps?.skipPromoScreen &&
      isBODiPromoModalEnabled() &&
      isUserAuthenticated
    );
  }, [isMobileDevice, playerProps, isUserAuthenticated]);

  const isWorkoutType = useMemo(() => {
    if (playerProps?.isWorkoutVideo) {
      return true;
    }

    if (searchVideoData) {
      const videoTypes = get(searchVideoData, 'analyticsData.workout.video.videoTypes', []);
      return videoTypes.includes(FREE_WORKOUT_TYPE);
    }

    return false;
  }, [playerProps, searchVideoData]);

  const shouldGetWaiver = useCallback(() => {
    if (!isWaiverRequired || !videoGUID) return false;

    // Don't get waiver for LO users unless required and it is a workout video
    if (!userGUID) {
      return isWaiverAnonymousUsersRequired && isWorkoutType;
    }

    // Get waiver for LI users
    return true;
  }, [isWaiverRequired, videoGUID, userGUID, isWaiverAnonymousUsersRequired, isWorkoutType]);

  // get video metadata from WebVideoPlayer's internal state
  const handleOnPlay = useCallback(() => {
    if (playerProps?.onPlay) playerProps.onPlay();
  }, [playerProps]);

  const closePlayer = useCallback(() => {
    setVideoPlayerOpen(false);
    setVideoPlayerProps(null);
    setIsWaiverChecked(false);
    setIsWaiverRequired(true);
    setIsWaiverLoading(false);
    setSearchVideoData(null);
    playerProps?.closeCallback && playerProps.closeCallback();
  }, [
    playerProps,
    setIsWaiverChecked,
    setIsWaiverRequired,
    setIsWaiverLoading,
    setSearchVideoData,
    setVideoPlayerOpen,
    setVideoPlayerProps,
  ]);

  const handleOnClose = useCallback(async () => {
    const shouldShowPromoScreen = getShouldShowPromoScreen();

    if (shouldShowPromoScreen) {
      setIsPromoScreenOpen(true);
      return;
    }

    setShouldLoadVideoHistory(true);
    closePlayer();
  }, [getShouldShowPromoScreen, setShouldLoadVideoHistory, closePlayer, setIsPromoScreenOpen]);

  const handleOnAnyAction = useCallback(
    (action: { type: string; payload: unknown }) => {
      dispatchEvent(SupportedEvents.onAnyVideoPlayerAction, action);
      if (playerProps?.onAnyAction) playerProps.onAnyAction(action);
    },
    [playerProps, dispatchEvent],
  );

  const handleOnRedirectToPromoPage = useCallback(
    (route: string) => {
      setShouldLoadVideoHistory(true);
      setIsPromoScreenOpen(false);
      closePlayer();
      redirect(route);
    },
    [setShouldLoadVideoHistory, setIsPromoScreenOpen, closePlayer, redirect],
  );

  const handleOnClosePromoScreen = useCallback(() => {
    setShouldLoadVideoHistory(true);
    setIsPromoScreenOpen(false);
    closePlayer();
  }, [closePlayer, setIsPromoScreenOpen, setShouldLoadVideoHistory]);

  const handleOnWaiverAccepted = useCallback(() => {
    setUserWaiverAccepted(videoGUID, brandCode);
    setIsWaiverAnonymousUsersRequired(false);
    setIsWaiverRequired(false);
  }, [
    brandCode,
    videoGUID,
    setUserWaiverAccepted,
    setIsWaiverAnonymousUsersRequired,
    setIsWaiverRequired,
  ]);

  // hold a reference to the WebVideoPlayer's internal actions to control it externally
  const handleActionsRef = useCallback<NonNullable<VideoPlayerProps['actionsRef']>>(
    (playerActions) => {
      if (playerProps?.actionsRef) playerProps.actionsRef(playerActions);
    },
    [playerProps],
  );

  // redirect BODi videos to app download page for mobile users
  useEffect(() => {
    if (isOpen && isMobile && isBODi) {
      redirect(`${BOD}${DOWNLOAD_APP}`);
      closePlayer();
    }
  }, [isOpen, isMobile, isBODi, redirect, closePlayer]);

  // fetch initial data
  useEffect(() => {
    getUserWaiverContent();
    setVideoPlayerActionsMap(WebVideoPlayer.actionsMap);
  }, [getUserWaiverContent, setVideoPlayerActionsMap, WebVideoPlayer.actionsMap]);

  // check video waiver status
  useEffect(() => {
    (async () => {
      if (!isOpen || isAccessTokenLoading || isWaiverChecked || isWaiverLoading) return;

      // skip check if checked already
      const noWaiverRequired = noWaiverRequiredList.includes(videoGUID);

      if (!shouldGetWaiver() || noWaiverRequired) {
        setIsWaiverChecked(true);
        setIsWaiverRequired(false);
        return;
      }

      setIsWaiverLoading(true);

      const waiverStatus = await getWaiverStatus(
        userGUID,
        videoGUID,
        locale.selectedLocale,
        sso.accessToken || '',
        brandCode,
        refreshAccessToken,
      );

      if (!waiverStatus.isWaiverRequired) {
        setNoWaiverRequired(videoGUID);
      }

      setIsWaiverRequired(waiverStatus.isWaiverRequired);
      setIsWaiverChecked(true);
      setIsWaiverLoading(false);
    })();
  }, [
    isOpen,
    isWaiverLoading,
    noWaiverRequiredList,
    brandCode,
    userGUID,
    videoGUID,
    sso.accessToken,
    isAccessTokenLoading,
    locale.selectedLocale,
    refreshAccessToken,
    setIsWaiverChecked,
    setIsWaiverLoading,
    setIsWaiverRequired,
    setNoWaiverRequired,
    shouldGetWaiver,
    isWaiverChecked,
  ]);

  // send initial analytics event (for search videos)
  useEffect(() => {
    if (isOpen && searchVideoData?.analyticsData && sendVideoPlayerEvent) {
      const { from, workout, state, searchState } = searchVideoData.analyticsData;
      const tealiumData = { from, workout, state, searchState } as IVideoPlayerPropsEvent;
      sendVideoPlayerEvent(tealiumData);
    }
  }, [isOpen, searchVideoData?.analyticsData, sendVideoPlayerEvent]);

  // manage body scroll
  useEffect(() => {
    if (isOpen && !isWaiverRequired) disableBodyScroll(playerContainerElement || document.body);
    else clearAllBodyScrollLocks();
    return () => clearAllBodyScrollLocks();
  }, [isOpen, isWaiverRequired, playerContainerElement]);

  return isOpen && (playerProps || searchVideoData) ? (
    <VideoPortalContainer id="unification-videoPlayer-container" ref={setPlayerContainerElement}>
      {(() => {
        if (!isWaiverChecked || !userWaiverHasLoaded || isWaiverLoading)
          return <VideoPlayerSpinner isOpen />;

        if (isWaiverChecked && isWaiverRequired)
          return (
            <VideoWaiver
              onWaiverAccepted={handleOnWaiverAccepted}
              onWaiverDeclined={closePlayer}
              waiverAgreementBody={userWaiverContent}
            />
          );

        if (WebVideoPlayer.loaded && WebVideoPlayer.Component && !isPromoScreenOpen)
          return (
            <WebVideoPlayer.Component
              {...playerProps}
              actionsRef={handleActionsRef}
              userGUID={userGUID}
              videoGUID={videoGUID}
              bodiData={bodiData}
              getAccessToken={playerProps?.getAccessToken || getAccessToken}
              tealiumVisitorId={playerProps?.tealiumVisitorId || tealiumVisitorId}
              tealiumSessionId={playerProps?.tealiumSessionId || tealiumSessionId}
              language={playerProps?.language || locale.selectedLocale}
              autoplay={playerProps?.autoplay || searchVideoData?.shouldAutoPlay || true}
              closeCallback={handleOnClose}
              onPlay={handleOnPlay}
              onAnyAction={handleOnAnyAction}
              extraHeartbeatParams={
                playerProps?.extraHeartbeatParams || {
                  activitySync: true,
                }
              }
              config={{
                ...playerProps?.config,
                convivaCustomerKey:
                  playerProps?.config?.convivaCustomerKey || VIDEO_PLAYER.CONVIVA_CUSTOMER_KEY,
                chromecastAppId:
                  playerProps?.config?.chromecastAppId || VIDEO_PLAYER.CHROMECAST_APP_ID,
                cocoaApiUrl: playerProps?.config?.cocoaApiUrl || COCOA_API.URL,
                cocoaApiKey: playerProps?.config?.cocoaApiKey || COCOA_API.KEY,
                videoApiUrl: playerProps?.config?.videoApiUrl || VIDEO_PLAYER.API,
                videoApiKey: playerProps?.config?.videoApiKey || VIDEO_PLAYER.API_KEY,
                vhsApiUrl: playerProps?.config?.vhsApiUrl || `${VIDEO_PLAYER.VHS_API}/heartbeats`,
                vhsApiKey: playerProps?.config?.vhsApiKey || VIDEO_PLAYER.VHS_API_KEY,
                useCocoaApi: playerProps?.config?.useCocoaApi || VIDEO_PLAYER.USE_COCOA_API,
                videoApiRetryAttempts: VIDEO_PLAYER.RETRY_ATTEMPTS,
              }}
            />
          );
      })()}

      <CloseButton
        onClick={handleOnClose}
        image={`${resolveRelativeUrl(CloseIcon)}`}
        type="button"
        title={t(VideoPlayerLocale.closeButton)}
        aria-label={t(VideoPlayerLocale.closeButton)}
      />

      {isPromoScreenOpen && (
        <Promo
          onClose={handleOnClosePromoScreen}
          onRedirectToPromoPage={handleOnRedirectToPromoPage}
        />
      )}
    </VideoPortalContainer>
  ) : null;
};

export const mapDispatchToProps = {
  setVideoPlayerOpen,
  setIsWaiverChecked,
  setIsWaiverRequired,
  setIsWaiverLoading,
  setIsPromoScreenOpen,
  setVideoPlayerProps,
  setVideoPlayerActionsMap,
  setSearchVideoData: setVideoData,
  getUserWaiverContent: getUserWaiverContentActionCreator,
  setNoWaiverRequired: setNoWaiverRequiredAction,
  setIsWaiverAnonymousUsersRequired: setIsWaiverAnonymousUsersRequiredAction,
  setUserWaiverAccepted: setUserWaiverAcceptedActionCreator,
  setShouldLoadVideoHistory,
  sendVideoPlayerEvent,
  updateAccessToken,
};

export const mapStateToProps = (state: AppState) => ({
  isOpen: state.videoPlayer?.isOpen,
  isWaiverChecked: state.videoPlayer?.isWaiverChecked,
  isWaiverRequired: state.videoPlayer?.isWaiverRequired,
  isWaiverLoading: state.videoPlayer?.isWaiverLoading,
  isPromoScreenOpen: state.videoPlayer?.isPromoScreenOpen,
  playerProps: state.videoPlayer?.playerProps,
  searchVideoData: state.modules.search?.videoData,
  main: modulesByKeySelector(state, 'main'),
  locale: modulesByKeySelector(state, 'locale'),
  sso: modulesByKeySelector(state, 'sso'),
  isUserAuthenticated: isUserAuthenticatedSelector(state),
  userWaiverContent: userWaiverContentSelector(state),
  userWaiverHasLoaded: hasLoadedContentSelector(state),
  noWaiverRequiredList: noWaiverRequiredListSelector(state),
  isWaiverAnonymousUsersRequired: isWaiverAnonymousUsersRequiredSelector(state),
  isAccessTokenLoading: isAccessTokenLoadingSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Player);
