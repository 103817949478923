export enum SupportedEvents {
  onLoadingStart = 'onLoadingStart',
  onLoadingEnd = 'onLoadingEnd',
  onLogIn = 'onLogIn',
  onRefreshToken = 'onRefreshToken',
  onSignUp = 'onSignUp',
  onLogOut = 'onLogOut',
  onLocaleChange = 'onLocaleChange',
  onSearch = 'onSearch',
  onAccountClick = 'onAccountClick',
  onAccountRemoved = 'onAccountRemoved',
  onAccountAdd = 'onAccountAdd',
  onDnsClick = 'onDnsClick',
  onManageCookiesClick = 'onManageCookiesClick',
  onError = 'onError',
  onCustomNavigation = 'onCustomNavigation',
  onSearchPageItemClick = 'onSearchPageItemClick',
  onAnyVideoPlayerAction = 'onAnyVideoPlayerAction',
  onAnalyticsEvent = 'onAnalyticsEvent',
}

export enum SupportedAmplitudeEvents {
  view5erPromo = 'view5erPromo',
  select5erCTA = 'select5erCTA',
  selectNavigationItem = 'selectNavigationItem',
}
